import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const updateLegalWatchOptIn = createAppAsyncThunk(
  'newsletter/updateLegalWatchOptIn',
  async (legalWatchOptIn: boolean, { extra: { accountGateway } }) => {
    try {
      return accountGateway.updateLegalWatchOptIn(legalWatchOptIn)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
