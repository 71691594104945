import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { LogUserBody, LogUserResponse } from '../../../types/user.ts'

export const logUser = createAppAsyncThunk<LogUserResponse | { error: string }, LogUserBody>(
  'auth/logUser',
  async ({ email, password }: LogUserBody, { extra: { authGateway } }) => {
    const payload = {
      email,
      password,
    }
    try {
      const response = await authGateway.logUser(payload)
      return await response.json()
    } catch (error) {
      if (error instanceof Error) {
        return { error: error.message }
      } else {
        return { error: 'An unknown error occurred' }
      }
    }
  },
)
