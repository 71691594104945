import { EmailValidationConfirmation } from '../../components/EmailValidationConfirmation.tsx'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks.ts'
import { verifyEmail } from '../../store/auth/useCases/verifyEmail.ts'
import { selectIsLoading, selectTokenStatus, setIsLoading } from '../../store/auth/authSlice.ts'
import { EmailError } from '../../components/EmailError.tsx'

export function EmailVerification() {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const tokenStatus = useAppSelector(selectTokenStatus)
  const isLoading = useAppSelector(selectIsLoading)
  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      setTimeout(() => {
        dispatch(verifyEmail(token)).finally(() => dispatch(setIsLoading(false)))
      }, 2000) // Fake timeout of 2 seconds
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderConfirmedOrError = () => {
    if (isLoading) {
      return <div className={'text-xl font-semibold text-primary sm:text-3xl'}>Verification en cours...</div>
    }

    if (tokenStatus === 'validated') {
      return <EmailValidationConfirmation />
    }

    return <EmailError />
  }

  return <>{renderConfirmedOrError()}</>
}
