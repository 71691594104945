import React, { useRef, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'
import { selectAuthErrorMessage } from '../../store/auth/authSlice.ts'
import { logUser } from '../../store/auth/useCases/logUser.ts'
import { AppDispatch } from '../../store/configureStore.ts'
import { useAppDispatch, useAppSelector } from '../../store/hooks.ts'
import { resetRegisterStepper } from '../../store/stepper/steppersSlice.ts'
import {
  EmailErrors,
  hasAnyErrors,
  PasswordSimpleErrors,
  validateEmail,
  validatePasswordSimple,
} from '../../utils/formValidation.ts'
import { useToaster } from '../../utils/useToaster.ts'
import { Toaster } from '@/store/toasterSlice.ts'

type FormKey = 'email' | 'password'

const loginFormInitialState = {
  email: '',
  password: '',
}
const emailErrorsInitialState: EmailErrors = { emptyEmail: '', invalidFormat: '' }
const passwordSimpleErrorsInitialState: PasswordSimpleErrors = {
  emptyPassword: '',
}
const isEmailVerificationFeatureDisabled = import.meta.env.VITE_FEATURES_DISABLE_EMAIL_VERIFICATION === 'true'
const viteOlympeTrustedOrigins = import.meta.env.VITE_OLYMPE_TRUSTED_ORIGINS as string
const TRUSTED_ORIGINS = (viteOlympeTrustedOrigins ?? '').split(',')
const EVENT_TYPE_LOGIN = 'olympe.login' as const
type OlympeLoginEvent = {
  eventType: typeof EVENT_TYPE_LOGIN
  eventData: {
    email: string
    password: string
  }
}
type OlympeNoopEvent = {
  eventType: undefined
}
type OlympeEvent = OlympeLoginEvent | OlympeNoopEvent

export const useLoginHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { showToast } = useToaster()
  // Form values local state
  const [form, setForm] = useState(loginFormInitialState)
  // Errors local state
  const loginErrorMessage = useAppSelector(selectAuthErrorMessage)
  const [passwordSimpleErrors, setPasswordSimpleErrors] = useState<PasswordSimpleErrors>(
    passwordSimpleErrorsInitialState,
  )
  const [emailErrors, setEmailErrors] = useState<EmailErrors>(emailErrorsInitialState)

  // Refs
  const LegalMentionsRef = useRef<HTMLDivElement | null>(null)

  // Update state functions
  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    getHandler('email', e)
    setEmailErrors(validateEmail(e.target.value))
  }
  function handlePasswordChange(e: React.FocusEvent<HTMLInputElement>) {
    getHandler('password', e)
    setPasswordSimpleErrors(validatePasswordSimple(e.target.value))
  }

  // Validation functions for each input
  function handleEmailValidation(e: React.FocusEvent<HTMLInputElement>) {
    const emailErrors = validateEmail(e.target.value)
    setEmailErrors(emailErrors)
  }

  function handlePasswordValidation(e: React.FocusEvent<HTMLInputElement>) {
    const passwordErrors = validatePasswordSimple(e.target.value)
    setPasswordSimpleErrors(passwordErrors)
  }

  // Utils
  const [passwordInputType, setPasswordInputType] = useState<'password' | 'text'>('password')

  const togglePasswordInputType = () => {
    passwordInputType === 'password' ? setPasswordInputType('text') : setPasswordInputType('password')
  }

  const enableLoginFromParentWindow = () => {
    // do not add "message" event listener if no trusted origin is configured
    if (!TRUSTED_ORIGINS.length) return

    window.addEventListener('message', (event: MessageEvent<OlympeEvent>) => {
      if (TRUSTED_ORIGINS.includes(event.origin) && event.data.eventType === EVENT_TYPE_LOGIN) {
        const email = event.data.eventData.email
        const password = event.data.eventData.password
        const emailErrors = validateEmail(email)
        const passwordSimpleErrors = validatePasswordSimple(password)
        if (hasAnyErrors(emailErrors, passwordSimpleErrors)) {
          navigate(ROUTE_PATHS.unexpectedError)
          return
        }

        logUserAndRedirect({
          dispatch,
          navigate,
          showToast,
          email,
          password,
        })
      }
    })
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>) => {
    setPasswordInputType('password')
    e.preventDefault()
    setEmailErrors(validateEmail(form.email))
    setPasswordSimpleErrors(validatePasswordSimple(form.password))

    if (form.email.length && form.password.length && !hasAnyErrors(emailErrors, passwordSimpleErrors)) {
      await logUserAndRedirect({
        dispatch,
        navigate,
        showToast,
        email: form.email,
        password: form.password,
      })
    }
  }

  const handleResetRegisterStepper = () => {
    dispatch(resetRegisterStepper())
  }

  const getHandler = (name: FormKey, e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [name]: e.target.value })
  }

  return {
    form,
    LegalMentionsRef,
    loginErrorMessage,
    passwordInputType,
    emailErrors,
    passwordSimpleErrors,
    enableLoginFromParentWindow,
    handleSubmit,
    togglePasswordInputType,
    handleEmailChange,
    handlePasswordChange,
    handleEmailValidation,
    handlePasswordValidation,
    handleResetRegisterStepper,
  }
}

async function logUserAndRedirect({
  dispatch,
  navigate,
  showToast,
  email,
  password,
}: {
  dispatch: AppDispatch
  navigate: NavigateFunction
  showToast: ReturnType<typeof useToaster>['showToast']
  email: string
  password: string
}) {
  const response = await dispatch(logUser({ email, password })).unwrap()
  if ('data' in response) {
    const { emailValidated } = response.data
    return emailValidated || isEmailVerificationFeatureDisabled
      ? navigate(`${ROUTE_PATHS.greetingUser}`)
      : navigate(`${ROUTE_PATHS.accountVerificationInfo}?email=${email}`)
  } else if ('error' in response) {
    const toaster: Toaster = {
      toasterMessage: response.error ?? '',
      toasterType: 'identified',
    }
    showToast({ toaster })
  }
}
