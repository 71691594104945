import { TheIconOlympeLogoWithText } from '@/components/icons/TheIconOlympeLogoWithText'
import LeftScreenSection from './LeftScreenSection'

export function LoginPageLeftPart() {
  return (
    <div className={`hidden w-1/2 flex-col gap-14 overflow-auto rounded-2xl bg-gradient-301 pl-8 pr-7 pt-6 md:flex`}>
      <div>
        <TheIconOlympeLogoWithText className={'fill-primary'} />
      </div>
      <LeftScreenSection />
    </div>
  )
}
