import { useAppDispatch } from '../../store/hooks.ts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AsyncThunkAction } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { TheIconOlympeLogoWithText } from '../../components/icons/TheIconOlympeLogoWithText.tsx'

interface ActionFunctionResponse {
  sent: boolean
}
type ActionFunctionType = (email: string) => AsyncThunkAction<ActionFunctionResponse, string, NonNullable<unknown>>

interface EmailActionLayoutProps {
  title: string
  initialMessage: string
  successMessage: string
  actionFunction: ActionFunctionType
  redirectTo: string
}

export function EmailActionLayout({
  title,
  initialMessage,
  successMessage,
  actionFunction,
  redirectTo,
}: Readonly<EmailActionLayoutProps>) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isEmailSent, setIsEmailSent] = useState(false)

  useEffect(() => {
    const email = searchParams.get('retry')
    if (!email) return
    dispatch(actionFunction(email))
  }, [searchParams, dispatch, actionFunction])

  const resendEmail = async () => {
    const email = searchParams.get('email')
    if (email?.includes('@')) {
      const { sent } = await dispatch(actionFunction(email)).unwrap()
      if (sent) setIsEmailSent(true)
    } else {
      navigate(redirectTo)
    }
  }

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key !== 'Enter') return
    await resendEmail()
  }

  const renderLabel = () => {
    return !isEmailSent ? (
      <button
        onKeyUp={handleKeyPress}
        onClick={resendEmail}
        className="text-sm font-bold text-label hover:cursor-pointer hover:text-primary"
      >
        👉 Renvoyez-moi l’email.
      </button>
    ) : (
      <p className="text-sm font-bold text-label">{successMessage}</p>
    )
  }

  return (
    <div className="m-auto flex w-full flex-col items-center gap-12 text-center md:w-2/3">
      <div className="flex flex-col items-center gap-6 text-center">
        <TheIconOlympeLogoWithText className={'fill-primary'} />
        <div className="flex flex-col gap-4 sm:w-2/3">
          <h1 className="text-xl font-semibold text-primary sm:text-3xl">{title}</h1>
          <p className="text-base text-primary">{initialMessage}</p>
        </div>
      </div>
      <div className="mt-10 flex flex-col items-center justify-center text-center">
        <p className="text-sm leading-6 text-label">Vous n’avez rien reçu ?</p>
        {renderLabel()}
        <p className="text-sm leading-6 text-label">ou consultez vos spams.</p>
      </div>
    </div>
  )
}
