import {
  LEGAL_WATCH_FILTERS,
  useLegalWatchFiltersContainerHook,
} from '@/components/hooks/use-LegalWatchFiltersContainer.hook.tsx'
import { RegionPickerFilterContainer } from '@/components/RegionPickerFilterContainer.tsx'
import { DatePickerFilterContainer } from '@/components/DatePickerFilterContainer.tsx'
import { Button } from '@/components/ui/button.tsx'
import { TagPickerFilterContainer } from '@/components/TagPickerFilterContainer.tsx'
import { NewsletterNotificationToggle } from './NewsletterNotificationToggle.tsx'

export function LegalWatchFiltersContainer() {
  const { filters, onResetFilters } = useLegalWatchFiltersContainerHook()

  const getFilter = (label: string) => {
    switch (label) {
      case LEGAL_WATCH_FILTERS.DATE:
        return <DatePickerFilterContainer />
      case LEGAL_WATCH_FILTERS.TAG:
        return <TagPickerFilterContainer />
      default:
        return <RegionPickerFilterContainer />
    }
  }

  return (
    <div className={'flex flex-col justify-between gap-8 xl:flex-row xl:items-center'}>
      <div className={'flex items-center gap-4 overflow-auto'}>
        {filters.map((filter) => (
          <div key={filter.label}>{getFilter(filter.label)}</div>
        ))}
        <Button
          variant={'secondary'}
          className={'w-fit p-0 text-sm font-normal hover:cursor-pointer'}
          onClick={onResetFilters}
        >
          Réinitialiser
        </Button>
      </div>
      <div className={'flex items-center justify-center self-start xl:self-center'}>
        <NewsletterNotificationToggle />
      </div>
    </div>
  )
}
