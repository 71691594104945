import { ForgottenPasswordForm } from '../../components/ForgottenPasswordForm.tsx'
import { EmailError } from '../../components/EmailError.tsx'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import { DecodedToken } from '@/components/hooks/use-EmailError.hook.tsx'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import {
  selectIsLoading,
  selectTokenStatus,
  setIsLoading,
  setTokenAction,
  setTokenIssue,
  setTokenStatus,
  setUserEmail,
} from '@/store/auth/authSlice.ts'
import { ERROR_MESSAGES } from '@/utils/errorMessages.ts'

export function ResetPasswordVerification() {
  const [searchParams] = useSearchParams()
  const [token, setToken] = useState('')
  const dispatch = useAppDispatch()
  const tokenIssue = useAppSelector(selectTokenStatus)

  const isLoading = useAppSelector(selectIsLoading)

  useEffect(() => {
    dispatch(setIsLoading(true))
    const token = searchParams.get('token')
    if (!token) {
      dispatch(setTokenAction(undefined))
      dispatch(setTokenIssue(ERROR_MESSAGES.TOKEN_INVALID))
      dispatch(setIsLoading(false))
      return
    }
    try {
      const decoded = jwtDecode<DecodedToken>(token)
      dispatch(setUserEmail(decoded.email))
      dispatch(setTokenAction(decoded.action))
      if (!decoded.exp) {
        dispatch(setTokenAction(undefined))
        dispatch(setTokenIssue(ERROR_MESSAGES.TOKEN_INVALID))
        return
      }
      if (decoded.exp * 1000 < Date.now()) {
        dispatch(setTokenIssue(ERROR_MESSAGES.TOKEN_EXPIRED))
      } else {
        setToken(token)
        dispatch(setTokenStatus('validated'))
      }
    } catch {
      dispatch(setTokenAction(undefined))
      dispatch(setTokenIssue(ERROR_MESSAGES.TOKEN_INVALID))
    }
    setTimeout(() => {
      dispatch(setIsLoading(false))
    }, 2000)
  }, [searchParams])

  const renderFormOrError = () => {
    if (isLoading) {
      return <div className={'text-xl font-semibold text-primary sm:text-3xl'}>Verification en cours...</div>
    }

    if ((tokenIssue === ERROR_MESSAGES.TOKEN_INVALID || tokenIssue === ERROR_MESSAGES.TOKEN_EXPIRED) && !isLoading) {
      return <EmailError />
    }

    return <ForgottenPasswordForm token={token} />
  }
  return <>{renderFormOrError()}</>
}
