import { useNavigate } from 'react-router-dom'
import { useLoginHook } from './use-login.hook.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { useEffect } from 'react'
import { WretchAccountGateway } from '@/gateways/WretchAccount.gateway.ts'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import { setCurrentUser } from '@/store/auth/authSlice.ts'
import { simpleGPTApiWretch } from '@/gateways/olympeGptApiWretch.ts'
import { LoginPageRightPart } from '@/pages/login/LoginPageRightPart.tsx'
import { LoginPageLeftPart } from './LoginPageLeftPart.tsx'

export function LoginPage() {
  const { enableLoginFromParentWindow } = useLoginHook()

  enableLoginFromParentWindow()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    fetchUser()
    async function fetchUser() {
      const accountGateway = new WretchAccountGateway(simpleGPTApiWretch)
      const currentUser = await accountGateway.getCurrentUser()
      if (currentUser?.emailValidated === false) {
        navigate(`${ROUTE_PATHS.accountVerificationInfo}?email=${currentUser.email}`)
      }
      if (currentUser.emailValidated) {
        dispatch(setCurrentUser(currentUser))
        navigate(ROUTE_PATHS.greetingUser)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'relative flex h-screen p-7 2xl:mx-auto 2xl:max-w-[1400px]'}>
      <LoginPageLeftPart />
      <LoginPageRightPart />
    </div>
  )
}
