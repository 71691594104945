function LeftScreenSection() {
  return (
    <div className={'flex h-full flex-col gap-9'}>
      <div className={'flex flex-col gap-4'}>
        <p className={'text-2xl font-normal text-label'}>Simplifiez votre quotidien grâce à Olympe</p>
        <p className={'text-sm font-normal'}>
          Trouvez rapidement les réponses dont vous avez besoin.
          <br />
          Prenez des décisions éclairées en un temps record.
        </p>
      </div>
      <img
        src={'/assets/images/connexion-screenshot.png'}
        alt={'placeholder'}
        className={'max-h-[25.875rem] max-w-[34.1875rem] rounded-xl border border-iceberg object-cover object-left'}
      />
    </div>
  )
}
export default LeftScreenSection
