import { TheIconOlympeLogoWithoutText } from '@/components/icons/TheIconOlympeLogoWithoutText.tsx'
import BaseInput from '@/components/atomics/BaseInput.tsx'
import { getErrorMessages } from '@/utils/formValidation.ts'
import { TheIconHideEye } from '@/components/icons/TheIconHideEye.tsx'
import { TheIconVisibleEye } from '@/components/icons/TheIconVisibleEye.tsx'
import { Link, useNavigate } from 'react-router-dom'
import { BaseButton } from '@/components/atomics/BaseButton.tsx'
import { LoginRegisterToggle } from '@/components/LoginRegisterToggle.tsx'
import FooterLegalDocs from '@/components/registerSteps/FooterLegalDocs.tsx'
import { useLoginHook } from '@/pages/login/use-login.hook.ts'

export function LoginPageRightPart() {
  const {
    form,
    handleSubmit,
    handleEmailChange,
    handlePasswordChange,
    emailErrors,
    passwordInputType,
    passwordSimpleErrors,
    togglePasswordInputType,
    handleEmailValidation,
    handlePasswordValidation,
  } = useLoginHook()
  const navigate = useNavigate()

  return (
    <div className={'flex h-full w-full flex-col items-center gap-8 sm:px-4 sm:pt-8 md:w-1/2 lg:pl-8 lg:pr-6 xl:px-20'}>
      <div className={'flex h-[95%] w-full max-w-[500px] flex-col items-center justify-center gap-8'}>
        <div className={'flex h-full w-full flex-col'}>
          <div className={'mb-7 flex flex-col items-center gap-4'}>
            <TheIconOlympeLogoWithoutText width="41" height="40" className={'fill-primary'} />
            <h1 className="h-14 text-center text-4xl font-semibold text-primary">Connexion</h1>
          </div>
          <div className={'flex flex-col gap-7 overflow-auto'}>
            <form
              data-testid="login-form"
              className={'mb-12 flex h-2/3 flex-col gap-4'}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleSubmit(e)
                }
              }}
            >
              <BaseInput
                placeholder="Adresse email"
                name="email"
                label="Adresse email"
                type="email"
                value={form.email}
                onChange={handleEmailChange}
                onBlur={handleEmailValidation}
                errormessage={getErrorMessages(emailErrors)[0]}
              />
              <BaseInput
                placeholder="Mot de passe"
                name="password"
                label="Mot de passe"
                type={passwordInputType}
                value={form.password}
                onChange={handlePasswordChange}
                onBlur={handlePasswordValidation}
                errormessage={getErrorMessages(passwordSimpleErrors)[0]}
              >
                {passwordInputType === 'password' ? (
                  <TheIconHideEye
                    data-testid={'icon-eye'}
                    className="w-6 fill-primary hover:cursor-pointer"
                    onClick={togglePasswordInputType}
                  />
                ) : (
                  <TheIconVisibleEye
                    className="w-6 fill-primary hover:cursor-pointer"
                    onClick={togglePasswordInputType}
                  />
                )}
              </BaseInput>
              <div className="flex-flex-col self-end">
                <Link to="/reset-password" className="hover:opacity-70">
                  <p className="text-sm font-normal text-label">Mot de passe oublié ?</p>
                </Link>
              </div>
            </form>
            <div className={'mx-1 flex flex-col gap-4 sm:mx-0 sm:gap-10'}>
              <BaseButton className="w-2/3 self-center" label="Connexion" onClick={handleSubmit}>
                Connexion
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
      <div className={'flex flex-col items-center gap-10'}>
        <LoginRegisterToggle
          onClick={() => navigate('/register')}
          catchphrase={'Vous n’avez pas de compte ?'}
          linkLabel={'Créez un compte'}
        />
        <FooterLegalDocs />
      </div>
    </div>
  )
}
