import { BackButtonContainer } from '@/components/BackButtonContainer.tsx'
import { CustomizeWatchPanelDesktop } from '@/components/ds/CustomizeWatchPanelDesktop.tsx'
import { CustomizeWatchPanelMobile } from '@/components/CustomizeWatchPanelMobile.tsx'

interface Props {
  navigateToFavorites: () => void
}
export function LegalWatchDetailsHeader({ navigateToFavorites }: Readonly<Props>) {
  return (
    <div className={'flex items-center justify-between sm:items-start'}>
      <BackButtonContainer label={'Olympe'} />
      <CustomizeWatchPanelMobile onClick={navigateToFavorites} />
      <CustomizeWatchPanelDesktop
        className={'hidden h-full rounded-lg sm:flex sm:flex-col sm:items-end sm:gap-3.5'}
        onClick={navigateToFavorites}
      />
    </div>
  )
}
