import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoaderFunction, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { TheHeaderMobile } from '../../components/TheHeaderMobile.tsx'
import { TheSidebarDesktop } from '../../components/sidebar/TheSidebarDesktop.tsx'
import { TheSidebarFooter } from '../../components/sidebar/TheSidebarFooter.tsx'
import { TheSidebarMobile } from '../../components/sidebar/TheSidebarMobile.tsx'
import { logoutUser } from '@/store/auth/useCases/logoutUser.ts'
import { AppStore } from '@/store/configureStore.ts'
import { selectAllConversations } from '@/store/conversations/conversationsSlice.ts'
import { listUserConversations } from '@/store/conversations/useCases/listUserConversations.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'
import { AuthModalContainer } from '@/components/modals/containers/AuthModalContainer.tsx'
import { PaymentModalContainer } from '@/components/modals/containers/PaymentModalContainer.tsx'
import { CalendlyModalContainer } from '@/components/modals/containers/CalendlyModalContainer.tsx'

export const layoutLoader =
  ({ store }: { store: AppStore }): LoaderFunction =>
  async () => {
    store.dispatch(listUserConversations())
    return null
  }

export function DefaultLayout() {
  const dispatch = useAppDispatch()
  const conversations = useSelector(selectAllConversations)

  const [animationSidebar, setAnimationSidebar] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const { isUserConnected, isFreePlanUser } = useCurrentUserHook()

  useEffect(() => {
    const lastConversation = conversations[0]
    if (location.pathname === '/') navigate(`/conversations/${lastConversation.id}`)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setAnimationSidebar('animation-slide-out-sidebar-mobile')
  }

  const handleSignout = async () => {
    await dispatch(logoutUser())
  }

  const handleSidebar = () => {
    setAnimationSidebar('animation-slide-in-sidebar-mobile')
  }

  return (
    <div className="grid h-screen grid-cols-1 sm:grid-cols-[240px_minmax(0,1fr)]">
      {/* Sidebar */}
      <div className="hidden overflow-y-auto sm:block">
        <TheSidebarDesktop>
          <TheSidebarFooter
            onLogout={handleSignout}
            isUserConnected={isUserConnected}
            isFreePlanUser={isFreePlanUser}
          />
        </TheSidebarDesktop>
      </div>

      {/* Mobile Sidebar - Shown only on smaller screens */}
      <TheSidebarMobile
        additionalClasses={animationSidebar}
        conversations={conversations}
        onClose={handleClose}
        onSignout={handleSignout}
      />

      {/* Main Content Area */}
      <div className="flex w-full flex-col">
        <TheHeaderMobile onHandleSidebar={handleSidebar} />
        <Outlet />
      </div>
      <AuthModalContainer />
      <PaymentModalContainer />
      <CalendlyModalContainer />
    </div>
  )
}
