import { AuthModal } from '@/components/modals/AuthModal.tsx'
import { SignupForm } from '@/components/SignupForm.tsx'
import { DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog.tsx'
import { LoginRegisterToggle } from '@/components/LoginRegisterToggle.tsx'
import { SigninForm } from '@/components/SigninForm.tsx'
import { useAuthFormModal } from '@/components/modals/hooks/use-authFormModal.tsx'

export function AuthModalContainer() {
  const {
    isSignupFormDisplayed,
    isAuthModalOpen,
    toggleAuthForm,
    handleCloseAuthModal,
    onSigninSubmit,
    onSignupSubmit,
  } = useAuthFormModal()
  return (
    <AuthModal isOpen={isAuthModalOpen} onClose={handleCloseAuthModal}>
      <DialogHeader className={'mb-6 space-y-1'}>
        <DialogTitle className={'text-center text-2xl font-semibold leading-9 text-primary'}>
          {isSignupFormDisplayed ? 'Créez un compte' : 'Connectez-vous'}
        </DialogTitle>
        <DialogDescription className={'px-5 text-center text-base leading-5 text-label'}>
          pour accéder à toutes les fonctionnalités d’Olympe.
        </DialogDescription>
      </DialogHeader>
      {isSignupFormDisplayed ? <SignupForm onSubmit={onSignupSubmit} /> : <SigninForm onSubmit={onSigninSubmit} />}
      <DialogFooter className={'mx-auto mt-6'}>
        <LoginRegisterToggle
          onClick={toggleAuthForm}
          catchphrase={isSignupFormDisplayed ? 'Vous avez déjà un compte ?' : "Vous n'avez pas de compte ?"}
          linkLabel={isSignupFormDisplayed ? 'Connectez-vous' : 'Créez un compte'}
        />
      </DialogFooter>
    </AuthModal>
  )
}
