import { RegisterPageRightPart } from '@/components/registerSteps/credentials/right/RegisterPageRightPart.tsx'
import { RegisterPageLeftPart } from '@/components/registerSteps/credentials/left/RegisterPageLeftPart.tsx'

export function RegisterPage() {
  return (
    <div className={'flex min-h-screen p-7 xl:max-h-screen xl:gap-6 2xl:mx-auto 2xl:max-w-[1400px]'}>
      <RegisterPageLeftPart />
      <RegisterPageRightPart />
    </div>
  )
}
