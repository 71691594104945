function FooterLegalDocs() {
  return (
    <div className="mt-1 flex gap-4">
      <div className={'hover:cursor-pointer'}>
        <a
          className={'text-sm font-semibold text-primary hover:opacity-70'}
          href={import.meta.env.VITE_OLYMPE_LEGAL_MENTIONS_URL}
          target={'_blank'}
          rel="noreferrer"
        >
          Mentions légales
        </a>
      </div>
      <div className={'hover:cursor-pointer'}>
        <a
          className={'text-sm font-semibold text-primary hover:opacity-70'}
          href={import.meta.env.VITE_OLYMPE_CGU_URL}
          target={'_blank'}
          rel="noreferrer"
        >
          CGU
        </a>
      </div>
    </div>
  )
}

export default FooterLegalDocs
