import { ReactNode } from 'react'
import { CustomizeWatchPanelDesktop } from '@/components/ds/CustomizeWatchPanelDesktop.tsx'
import { CustomizeWatchPanelMobile } from '../CustomizeWatchPanelMobile'

interface Props {
  navigateToFavorites: () => void
  children: ReactNode
}
export function LegalWatchHeader({ navigateToFavorites, children }: Readonly<Props>) {
  return (
    <div className={'flex flex-col gap-6'}>
      <div className={'flex flex-row items-center justify-between gap-4 lg:gap-0'}>
        <h1 className={'text-[1.75rem] font-semibold leading-9'}>Veille juridique</h1>
        <CustomizeWatchPanelMobile onClick={navigateToFavorites} />
        <CustomizeWatchPanelDesktop
          className={'hidden h-full sm:flex sm:flex-col sm:gap-3.5 xl:flex-row'}
          onClick={navigateToFavorites}
        />
      </div>
      {children}
    </div>
  )
}
