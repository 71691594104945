import { TheIconOlympeLogoWithoutText } from '../../../icons/TheIconOlympeLogoWithoutText.tsx'
import { useCredentialsStepHook } from './use-credentialsStep.hook.tsx'
import { SignupForm } from '@/components/SignupForm.tsx'
import { LoginRegisterToggle } from '@/components/LoginRegisterToggle.tsx'
import { useAuthFormModal } from '@/components/modals/hooks/use-authFormModal.tsx'
import { useNavigate } from 'react-router-dom'
import { cn } from '@/lib/utils.ts'
import FooterLegalDocs from '@/components/registerSteps/FooterLegalDocs.tsx'

export function RegisterPageRightPart() {
  const navigate = useNavigate()
  const { navigationStyle, headerStyle } = useCredentialsStepHook()
  const { onSignupSubmit } = useAuthFormModal()

  return (
    <div
      className={
        'flex w-full flex-col items-center justify-between gap-4 sm:px-4 sm:pt-8 md:w-1/2 lg:pl-8 lg:pr-6 xl:w-[55%] xl:p-[2rem_7rem_0_7rem]'
      }
    >
      <div className={cn(`flex flex-col items-center gap-4 self-stretch`, headerStyle)}>
        <div className={`flex w-full flex-col items-start gap-4`}>
          <TheIconOlympeLogoWithoutText width="41" height="40" />
          <div className={'flex flex-col items-start gap-3 self-stretch'}>
            <h1 className="text-4xl font-semibold text-primary">Renforcez votre expertise en protection des données</h1>
            <h1 className="text-2xl font-normal text-primary">Commencez votre essai gratuit de 15 jours</h1>
          </div>
        </div>
        <SignupForm onSubmit={onSignupSubmit} />
      </div>
      <div className={cn('flex flex-col items-center justify-center self-stretch', navigationStyle)}>
        <LoginRegisterToggle
          onClick={() => navigate('/login')}
          catchphrase={'Vous avez déjà un compte ?'}
          linkLabel={'Connectez-vous'}
        />
      </div>
      <div className={'md:hidden'}>
        <FooterLegalDocs />
      </div>
    </div>
  )
}
