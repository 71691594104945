import { Button } from '@/components/ui/button.tsx'
interface Props {
  catchphrase: string
  linkLabel: string
  onClick: () => void
}
export function LoginRegisterToggle({ catchphrase, linkLabel, onClick }: Readonly<Props>) {
  return (
    <Button onClick={onClick} variant={'link'}>
      <div className={'flex flex-col items-center self-stretch hover:opacity-70'}>
        <p className={'text-center text-sm text-label'}>{catchphrase}</p>
        <p className={'text-center text-sm font-semibold text-primary'}>{linkLabel}</p>
      </div>
    </Button>
  )
}
