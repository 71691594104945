import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { User } from '@/types/user.ts'
import { TokenIssue } from '@/store/auth/authSlice.ts'
import { ERROR_MESSAGES } from '@/utils/errorMessages.ts'

export type DecodedTokenAction = 'account_validation' | 'reset_password'
export type DecodedToken = JwtPayload & {
  action: DecodedTokenAction
  email: User['email']
}
export const useEmailErrorHook = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const navigateTo = ({ pathname, search }: { pathname: string; search?: string }) => {
    return navigate({
      pathname,
      search,
    })
  }

  const handleClick = ({ tokenIssue }: { tokenIssue: TokenIssue }) => {
    if (token) {
      try {
        const decoded = jwtDecode<DecodedToken>(token)
        const searchRetry = `?retry=${decoded.email}`
        if (decoded.action === 'account_validation' && tokenIssue === ERROR_MESSAGES.TOKEN_EXPIRED) {
          navigateTo({
            pathname: ROUTE_PATHS.accountVerificationInfo,
            search: searchRetry,
          })
        }

        if (decoded.action === 'reset_password' && tokenIssue === ERROR_MESSAGES.TOKEN_EXPIRED) {
          navigateTo({
            pathname: ROUTE_PATHS.resetPasswordInfo,
            search: searchRetry,
          })
        }
      } catch {
        navigateTo({
          pathname: ROUTE_PATHS.login,
        })
      }
    } else {
      navigateTo({
        pathname: ROUTE_PATHS.login,
      })
    }
  }

  const handleLabel = (tokenIssue: TokenIssue) => {
    return tokenIssue === ERROR_MESSAGES.TOKEN_EXPIRED ? 'Renvoyez-moi un lien' : 'Je veux réessayer'
  }

  return {
    handleClick,
    handleLabel,
  }
}
