import { SVGProps } from 'react'
import { cn } from '@/lib/utils.ts'

export function BellIcon({ className, ...props }: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} className={cn('w-5', className)} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9468_6651)">
        <path
          d="M13.0384 15.0116C13.3958 16.3452 12.6043 17.7161 11.2707 18.0734C9.93699 18.4308 8.56615 17.6393 8.2088 16.3057M1.71419 9.67702C1.38961 8.51187 1.72715 7.25217 2.59082 6.40541M10.1221 4.78331C10.3756 4.32722 10.4591 3.77597 10.3134 3.23214C10.0156 2.12076 8.87322 1.46121 7.76183 1.759C6.65044 2.0568 5.9909 3.19917 6.28869 4.31056C6.43441 4.85439 6.78236 5.29003 7.22994 5.55826M16.9327 5.59927C16.6312 4.42793 15.709 3.50576 14.5377 3.20428M14.5757 7.87034C14.2782 6.76022 13.4841 5.83192 12.3681 5.28965C11.252 4.74737 9.90541 4.63554 8.62451 4.97875C7.34361 5.32197 6.23334 6.09212 5.53794 7.11977C4.84254 8.14743 4.61898 9.34842 4.91643 10.4585C5.40858 12.2952 5.31301 13.7605 5.00222 14.874C4.648 16.1431 4.47089 16.7776 4.51873 16.905C4.57348 17.0508 4.61308 17.0908 4.75827 17.147C4.88518 17.1962 5.41832 17.0533 6.48461 16.7676L16.3722 14.1183C17.4384 13.8326 17.9716 13.6897 18.0569 13.5837C18.1545 13.4623 18.1688 13.4079 18.1433 13.2543C18.1211 13.12 17.6504 12.6591 16.7091 11.7371C15.8833 10.9283 15.0678 9.70705 14.5757 7.87034Z"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9468_6651">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
