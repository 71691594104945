export const ERROR_MESSAGES = {
  GENERIC: 'Nous sommes désolés, une erreur est survenue.',
  WILL_LOGOUT: 'Session expirée, veuillez vous reconnecter.',
  CREDENTIALS: 'Nom d’utilisateur ou mot de passe incorrect.',
  USER_ALREADY_EXISTS: 'Un compte existe déjà avec cette adresse email.',
  TOKEN_EXPIRED: 'Lien expiré...',
  TOKEN_INVALID: 'Lien invalide...',
} as const

export const ERROR_ACTIONS_MESSAGES = {
  REFRESH: 'Actualisez la page.',
} as const
