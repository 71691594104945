import { BellIcon } from './icons/BellIcon'
import { Switch } from './ui/switch'
import { useAppDispatch } from '@/store/hooks.ts'
import { updateLegalWatchOptIn } from '@/store/newsletter/useCases/updateLegalWatchOptIn.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'

export function NewsletterNotificationToggle() {
  const dispatch = useAppDispatch()
  const { legalWatchOptIn } = useCurrentUserHook()
  const handleChange = (value: boolean) => {
    dispatch(updateLegalWatchOptIn(value))
  }
  return (
    <div className={'flex items-center gap-2'}>
      <BellIcon />
      <span className={'text-sm'}>Recevoir la veille par email</span>
      <Switch defaultChecked={legalWatchOptIn} onCheckedChange={handleChange} />
    </div>
  )
}
