import { TheIconOlympeLogoWithText } from './icons/TheIconOlympeLogoWithText.tsx'
import { BaseButton } from './atomics/BaseButton.tsx'
import { useEmailErrorHook } from '@/components/hooks/use-EmailError.hook.tsx'
import { useAppSelector } from '@/store/hooks.ts'
import { selectTokenIssue } from '@/store/auth/authSlice.ts'

export function EmailError() {
  const tokenIssue = useAppSelector(selectTokenIssue)
  const { handleClick, handleLabel } = useEmailErrorHook()

  const contactMail = import.meta.env.VITE_OLYMPE_CONTACT_MAIL
  return (
    <div className={'flex h-screen w-full items-center justify-center text-center'}>
      <div className={'flex w-11/12 flex-col items-center gap-10'}>
        <div className={'flex flex-col items-center gap-6'}>
          <TheIconOlympeLogoWithText className={'fill-primary'} />
          <div className={'flex flex-col gap-4'}>
            <h1 className={'text-xl font-semibold text-primary sm:text-3xl'}>{tokenIssue}</h1>
            <div className={'flex flex-col items-center justify-center'}>
              <span className={'text-base text-primary'}>Mais pas de panique !</span>
              <span className={'text-base font-bold text-primary'}>Cliquez simplement sur le bouton ci-dessous :</span>
            </div>
            <div className={'flex items-center justify-center'}>
              👉{' '}
              <BaseButton
                onClick={() => handleClick({ tokenIssue })}
                size={'small'}
                className={'ml-2'}
                label={handleLabel(tokenIssue)}
              />
            </div>
          </div>
        </div>

        <div className={'flex flex-col'}>
          <span className={'mb-1 text-base font-bold text-label'}>Besoin {"d'un"} coup de main ?</span>
          <p className={'text-sm text-label'}>
            Contactez-nous à{' '}
            <a href={`mailto:${contactMail}`} className={'text-sm font-bold text-label'}>
              contact@olympe.legal
            </a>
            <br /> et notre équipe sera ravie de vous aider.
          </p>
        </div>
      </div>
    </div>
  )
}
