import { XMarkIcon } from '@heroicons/react/16/solid'
import { ReactElement, ReactNode } from 'react'
import { ToasterPosition, ToasterType } from '../../store/toasterSlice.ts'
import { useToaster } from '../../utils/useToaster.ts'
import { useToasterHook } from './use-toaster.hook.tsx'

type Props = {
  message: string | ReactElement
  actionMessage?: string
  onClick?: () => void
  icon?: ReactElement
  toasterType: ToasterType
  toasterPosition?: ToasterPosition
  children: ReactNode
}

export function BaseToaster({
  message,
  toasterType,
  toasterPosition,
  actionMessage,
  onClick,
  children,
}: Readonly<Props>) {
  const { backgroundColor, position } = useToasterHook({ toasterType, toasterPosition })
  const { close: closeToaster } = useToaster()

  const renderActionMessage = () => {
    if (!actionMessage) return
    return (
      <button className={'text-sm font-bold text-primary'} onClick={onClick}>
        {actionMessage}
      </button>
    )
  }

  return (
    <>
      {message && (
        <div
          className={`fixed left-0 right-0 z-20 m-auto items-center ${backgroundColor} mx-auto flex h-12 w-fit items-center gap-4 rounded-lg px-4 ${position} md:h-10`}
        >
          <div className="flex items-center">{children}</div>
          <div className="flex cursor-default gap-1 py-2 text-base leading-tight text-neutral-800">
            <div className="flex justify-between">
              <p className="text-sm font-normal text-primary">{message}</p>
            </div>
            {renderActionMessage()}
          </div>
          <XMarkIcon className="h-4 w-4 cursor-pointer" onClick={closeToaster} />
        </div>
      )}
    </>
  )
}
