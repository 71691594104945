import {
  GetCurrentUserResponse,
  GetCurrentUserResponseData,
  ResetPasswordResponse,
  ResetPasswordResponseData,
  SendEmailVerificationResponse,
  SendEmailVerificationResponseData,
  SendPasswordResetEmailResponse,
  SendPasswordResetEmailResponseData,
  UpdateUserRequest,
  UpdateUserResponse,
  UpdateUserResponseData,
  VerifyEmailResponse,
  VerifyEmailResponseData,
} from '../types/user.ts'
import { ERROR_MESSAGES } from '../utils/errorMessages.ts'
import { AccountGateway } from './interface/account.gateway.ts'
import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'

export class WretchAccountGateway implements AccountGateway {
  private readonly endpoint = '/account'
  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async resetPassword(newPassword: string, token: string): Promise<ResetPasswordResponseData> {
    const body = {
      data: {
        newPassword,
        token,
      },
    }

    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/reset-password`
    const response = await this.olympeGptApi
      .url(requestInfoOrUrl)
      .options({ credentials: undefined })
      .post(body)
      .json<ResetPasswordResponse>()
    return response.data
  }

  async sendPasswordResetEmail(email: string): Promise<SendPasswordResetEmailResponseData> {
    const body = {
      data: {
        email,
      },
    }

    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/send-password-reset`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).post(body).json<SendPasswordResetEmailResponse>()
    return response.data
  }

  async sendEmailVerification(email: string): Promise<SendEmailVerificationResponseData> {
    const body = {
      data: {
        email,
      },
    }

    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/send-email-verification`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).post(body).json<SendEmailVerificationResponse>()
    return response.data
  }

  async verifyEmail(token: string): Promise<VerifyEmailResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/verify-email?token=${token}`
    const response = await this.olympeGptApi
      .url(requestInfoOrUrl)
      .get()
      .error(500, (error) => {
        if (error.json.message === 'Token expired') {
          throw new Error(ERROR_MESSAGES.TOKEN_EXPIRED)
        }
        if (error.json.message === 'Token invalid') {
          throw new Error(ERROR_MESSAGES.TOKEN_INVALID)
        }
      })
      .json<VerifyEmailResponse>()
      .catch(async (err) => {
        if (err?.message) {
          throw new Error(err.message)
        }
        throw new Error(ERROR_MESSAGES.GENERIC)
      })
    return response.data
  }

  async getCurrentUser(): Promise<GetCurrentUserResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/current-user`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<GetCurrentUserResponse>()
    return response.data
  }

  async updateUser(body: UpdateUserRequest): Promise<UpdateUserResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/update`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).put({ data: body }).json<UpdateUserResponse>()
    return response.data
  }

  async updateLegalWatchOptIn(legalWatchOptIn: boolean): Promise<void> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/legal-watch-opt-in`
    await this.olympeGptApi.url(requestInfoOrUrl).patch({ data: { legalWatchOptIn } }).json()
  }
}
