import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const verifyEmail = createAppAsyncThunk(
  'auth/verifyEmail',
  async (token: string, { extra: { accountGateway } }) => {
    try {
      return await accountGateway.verifyEmail(token)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
